/* fonts */
/* Heebo Extra Light */
@font-face {
  font-family: 'Heebo';
  src:
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-200-normal.woff2) format('woff2'), /* Super Modern Browsers */
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-200-normal.woff) format('woff'); /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
  font-display: fallback;
}

/* Heebo Light */
@font-face {
  font-family: 'Heebo';
  src:
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-300-normal.woff2) format('woff2'), /* Super Modern Browsers */
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-300-normal.woff) format('woff'); /* Legacy iOS */
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}


/* Heebo Regular */
@font-face {
  font-family: 'Heebo';
  src:
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-400-normal.woff2) format('woff2'), /* Super Modern Browsers */
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-400-normal.woff) format('woff'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}


/* Heebo Medium */
@font-face {
  font-family: 'Heebo';
  src:
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-500-normal.woff2) format('woff2'), /* Super Modern Browsers */
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-500-normal.woff) format('woff'); /* Legacy iOS */
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}


/* Heebo Bold */
@font-face {
  font-family: 'Heebo';
  src:
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-700-normal.woff2) format('woff2'), /* Super Modern Browsers */
          url(/skin/frontend/default/juwelo2/fonts/heebo-latin-700-normal.woff) format('woff'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/skin/frontend/default/juwelo2/fonts/icomoon0924.eot');
  src: url('/skin/frontend/default/juwelo2/fonts/icomoon0924.eot?#iefix') format('embedded-opentype'),
  url('/skin/frontend/default/juwelo2/fonts/icomoon0924.woff') format('woff'),
  url('/skin/frontend/default/juwelo2/fonts/icomoon0924.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Icons';
  src: url('/skin/frontend/default/juwelo2/fonts/icons.eot');
  src: url('/skin/frontend/default/juwelo2/fonts/icons.eot?#iefix') format('embedded-opentype'),
  url('/skin/frontend/default/juwelo2/fonts/icons.woff') format('woff'),
  url('/skin/frontend/default/juwelo2/fonts/icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

html, body, button, div, span, applet, object, iframe, h1,
h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong,
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label,
legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  text-rendering: geometricPrecision;
}
html {
  scroll-behavior: smooth;
}
/* allows touch start/end/move not to drag the page sideways */
html, body {
  overflow-x: hidden;
  max-width: 100%;
}
html:has(.header-box-open) {
  overflow-y: hidden;
}
*[x-cloak] {
  display:none !important;
}

body .main-wrapper iframe {
  display: block;
}

.main-wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
}
.main-wrapper picture img:not(#large_image) {
  width: 100%;
}

:focus {
  outline: 0;
}
.active {
  z-index: 1000;
}
.open {
  z-index: 1001;
}

ul, ol {
  list-style: none;
}

hr {
  border: none;
  height: 0;
  width: 100%;
  clear: both;
}

/* html5 elements */
header, footer, nav, article, address, section {
  display: block;
}

/* show hide mobile / desktop */
@media screen and (max-width: 1024px) {
  body .main-wrapper .mobile {
    display: block;
  }

  body .main-wrapper .desktop {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  body .main-wrapper .mobile {
    display: none;
  }

  body .main-wrapper .desktop {
    display: block;
  }
}

/* typography */

body, table {
  .sans-serif-light();
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.075em;
  color: @darkgrey;
}
.nowrap {
  white-space: nowrap;
}
.text-white {
  color: @white;
}

.text-black {
  color: @darkgrey;
}
h1, .h1 {
  .sans-serif-light();
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 0 15px;
  span {
    .sans-serif-regular();
  }
}

h2, .h2 {
  .sans-serif-regular();
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  span {
    .sans-serif-light();
  }
}


// Homepage Layout
.cms-index-index {
  .main.max-width {
    max-width: none;
    .col-set {
      padding: 0;
    }
  }
  .clear.red {
    display: none;
  }
  h2 {
    .sans-serif-regular();
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: none;
    margin: 0 0 10px;
  }
  .about-juwelo {
    padding: 0 5px;
    .col {
      margin: 0 0 30px;
    }
    h5 {
      margin: 0 0 10px;
      .sans-serif-regular();
      font-size: 13px;
      line-height: 20px;
    }
  }
}
@media screen and (min-width: 768px) {
  h2 {
    margin: 10px 0 20px;
  }

  .cms-index-index {
    h2 {
      font-size: 22px;
      line-height: 22px;
      margin: 0 0 28px;
    }
    .about-juwelo {
      .col {
        margin: 0 0 60px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .cms-index-index {
    .about-juwelo {
      padding: 0 10px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .cms-index-index {
    .about-juwelo {
      max-width: 1200px;
      margin: 0 auto 70px;
    }
  }
}

h3, .h3 {
  .sans-serif-medium();
  font-size: 15px;
  line-height: 15px;
  margin: 1.5em 0 0.5em;
}

h4, .h4 {
  .sans-serif-regular();
  font-size: 13px;
  text-transform: uppercase;
  margin: 1.5em 0 0.5em;
}

h5, .h5 {
  .sans-serif-regular();
  font-size: 13px;
  margin: 1.5em 0 0.5em;
}

.block-title h3,
.block-title h5 {
  .sans-serif-regular();
  font-size: 100%;
  margin: 0;
}

p, li {
  line-height: 150%;
  margin: 0 0 1em;
}

blockquote {
  margin: 30px 10px;
  .sans-serif-regular();
  font-size: 120%;
  font-style: italic;
  line-height: 150%;
  text-align: center;
  color: @pink;
}

blockquote strong {
  font: inherit;
}

blockquote p {
  .sans-serif-light();
  font-size: 10px;
  font-style: normal;
  line-height: 150%;
  text-align: right;
  color: @mediumgrey;
}

small {
  font-size: 90%;
}

b,
strong {
  font-family: inherit;
}

sub {
  vertical-align: sub;
  font-size: 80%;
}

sup {
  vertical-align: super;
  font-size: 80%;
}
.sans-serif-light { .sans-serif-light(); }
.sans-serif-regular { .sans-serif-regular(); }
.sans-serif-bold {.sans-serif-bold();  }
.serif-regular { font-family:  @serif-normal; }
.serif-bold {  font-family:  @serif-normal; font-weight: bold; }

.page-content p a,
.page-content p b,
.page-content p strong {
  .sans-serif-medium();
}

.x-large-text {
  font-size: 150%;
}

.block-title strong {
  font-size: 15px;
}

.strike {
  display: none;
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 0;
}

.strike:after {
  display: block;
  position: absolute;
  height: 2px;
  width: 90%;
  margin: 0 5%;
  background: @red;
  opacity: 0.7;
  font-size: 0;
  line-height: 0;
  content: ' ';
}

.strike:after {
  transform: rotate(165deg);
}
.underline {
  text-decoration: underline;
}
/* Links / Buttons */
a, .login-layer-link {
  color: @pink;
  text-decoration: none;
}

a:hover, .login-layer-link:hover {
  text-decoration: underline;
}

a.more,
a.less,
.more a {
  .sans-serif-bold();
  font-size: 13px;
  color: @pink;
  cursor: pointer;
}

a.less:before,
.more a:before {
  display: inline-block;
  margin-right: 5px;
  font-size: 13px;
  content: '-';
}

a.more:before,
.more.show a:before {
  display: inline-block;
  margin-right: 5px;
  font-size: 13px;
  content: '+';
}

button,
.button:not(.input-box),
.btn {
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid @pink;
  background: @pink;
  .sans-serif-light();
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  color: @white;
  letter-spacing: 0.1em;
  cursor: pointer;
  user-select: none;
  &.big {
    padding: 17px 42px;
    font-size: 20px;
  }
  &:hover {
    text-decoration: none;
    background: lighten(@pink, 5%);
  }
  &.white {
    color: @pink;
    background-color: @white;
    border-color: @pink;
    &:hover {
      background-color: darken(@lightgrey, 5%);
    }
  }
  &.button-white-transparent {
    background-color: transparent;
    border-color: @white;
    color: @white;
    .sans-serif-regular();
  }
  &.green {
    background: @green;
    border-color: @green;
    &:hover {
      background-color: lighten(@green, 5%);
    }
  }
  &.gold {
    background: @juwelogold;
    border-color: @juwelogold;
    &:hover {
      background-color: lighten(@juwelogold, 5%);
    }
  }
  &.purple {
    background: @purple;
    border-color: @purple;
    &:hover {
      background-color: lighten(@purple, 5%);
    }
  }
  &.grey {
    border-color: @mediumgreybutton;
    background-color: @mediumgreybutton;
    color: @white;
    &:hover {
      background-color: darken(@mediumgreybutton, 5%);
    }
  }
  &.orange {
    border-color: @orange;
    background-color: @orange;
    color: @white;
    &:hover {
      background-color: darken(@orange, 5%);
    }
  }
  &.small {
    padding: 5px 10px 3px;
    line-height: 9px;
    font-size: 9px;
  }
  &.medium {
    padding: 9px 10px 7px;
    line-height: 10px;
    font-size: 10px;
  }
  &.deactivated {
    opacity: 0.2;
    cursor: default;
  }
}
#btn_paypal {
  display: block;
  max-width: 500px;
  margin: 20px auto;
  border: 1px solid #359edb;
  border-radius: 6px;
  background-color: @white;
  color: #359edb;
  &:hover {
    border-color: #003087;
    color: #003087;
  }
  img {
    margin: 20px auto 0;
    max-width: 150px;
  }
  .text {
    display: block;
    box-sizing: border-box;
    padding: 10px 25px 20px;
    line-height: 20px;
  }
}
#btn_ideal {
  display: block;
  max-width: 500px;
  margin: 20px auto;
  border: 1px solid #cc0066;
  background-color: @white;
  color: #cc0066;
  &:hover {
    border-color: #cc0066;
    color: #cc0066;
  }
  img {
    margin: 20px auto 0;
    max-width: 150px;
  }
  .text {
    display: block;
    box-sizing: border-box;
    padding: 10px 25px 20px;
    line-height: 20px;
  }
}

/* price-currency */
.de, .fr, .it {
  .price-currency:after {
    content: ' €';
  }
}
.nl, .en, .es {
  .price-currency:before {
    content: '€ ';
  }
}


a.secondary {
  display: inline-block;
  cursor: pointer;
  float: right;
  padding: 8px 0;
  .sans-serif-bold();
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.mailto a,
.print-page a {
  display: block;
  float: right;
  width: 30px;
  height: 20px;
  background: @pink;
  color: #ffffff;
  text-align: center;
  font-size: 0;
}

.mailto a:before {
  display: block;
  font-family: @icon;
  font-size: 13px;
  content: '\f0e0';
}

.print-page a:before {
  display: block;
  font-family: @icon;
  font-size: 12px;
  content: '\f02f';
}

.info-link {
  display: inline-block;
  &:before {
    position: relative;
    z-index: 10;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid @pink;
    color: @pink;
    text-align: center;
    .sans-serif-bold();
    font-size: 12px;
    line-height: 16px;
    content: 'i';
    text-indent: 1px;
  }
  &.close-info:before {
    top: -1px;
    content: 'x';
  }
}

/* icons */

.wishlist-icon:before {
  display: inline-block;
  font-family: @icon;
  content: '\e915';
}
.is-on-wishlist .wishlist-icon:before,
.wishlist-icon.is-on-wishlist:before {
  content: '\e916';
}
.remove-from-wishlist {
  .wishlist-icon:before {
    content: '\e916';
  }
}
.auction-icon:before {
  display: inline-block;
  font-family: @icon;
  content: '\f0e3';
}

.compare-icon:before {
  display: inline-block;
  font-family: @icon;
  content: '\f24d';
}

.icon-ja:before,
.icon-yes:before {
  display: inline-block;
  font-family: @icon;
  color: #0a8e21;
  content: '\f00c';
}
.icon-yes.white:before {
  color: #FFFFFF;
}

.icon-nein:before,
.icon-no:before {
  display: inline-block;
  font-family: @icon;
  color: #D70F41;
  content: '\f00d';
}

.email-icon:before,
.icon-email:before {
  display: inline-block;
  font-family: @icon;
  content: '\f0e0';
}

.icon-remove:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: @mediumgrey;
  text-align: center;
  color: #ffffff;
  content: 'x';
}

.icon-gift:before {
  display: inline-block;
  font-family: @icon;
  content: '\f06b';
}

.icon-clock:before {
  display: inline-block;
  font-family: @icon;
  content: '\f017';
}

.icon-telephone:before {
  display: inline-block;
  font-family: @icon;
  content: '\f095';
  font-size: 14px;
}

.icon-whatsapp:before {
  display: inline-block;
  font-family: @icon;
  content: '\e938';
  color: inherit;
}



.pdf:before {
  display: inline-block;
  margin-right: 5px;
  font-family: @icon;
  content: '\f1c1';
}

.download:before {
  display: inline-block;
  margin-right: 5px;
  font-family: @icon;
  content: '\f019';
}

.trash-icon {
  &:before {
    display: inline-block;
    font-family: @icon;
    content: '\f014';
  }
}

/* account-links */
.account-links {
  li {
    .new {
      position: relative;
      top: -1px;
      display: inline-block;
      margin: 0 5px 0 0;
      padding: 0px 4px;
      background-color: @pink;
      .sans-serif-bold();
      font-size: 11px;
      line-height: 15px;
      color: @white;
    }
    strong:before,
    a:before {
      display: inline-block;
      width: 20px;
      margin-right: 5px;
      font-family: 'FontAwesome';
    }
    .account_link:before {
      content: '\f007';
    }
    .account_edit_link:before {
      content: '\f044';
    }
    .account_orders_link:before {
      content: '\e912';
    }
    .account_live_link:before {
      content: '\f0e3';
    }
    .address_book_link:before {
      content: '\f2b9';
    }
    .account_creditcards_link:before {
      content: '\f09d';
    }
    .account_newsletter_link:before {
      content: '\f1ea';
    }
    .account_wishlist_link:before {
      content: '\f004';
    }
    .account_logout_link:before {
      content: '\f08b';
    }
    .account_retour_link:before {
      content: '\e9b0';
    }
  }
}
.close {
  position: absolute;
  top: 0;
  right: 5px;
  display: inline-block;
  cursor: pointer;
  &:after {
    display: inline-block;
    width: 20px;
    height: 20px;
    float: left;
    .icon();
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    content: '\e901';
  }
}

.flag {
  display: inline-block;
  width: 26px;
  height: 20px;
  background-image: url('../images/flags.png');
  background-position-y: 0;
  margin-right: 5px;
  &.de { background-position-x: 0; }
  &.at { background-position-x: -26px; }
  &.eu { background-position-x: -53px; }
  &.fr { background-position-x: -78px; }
  &.it { background-position-x: -104px; }
  &.dk { background-position-x: -130px; }
  &.be { background-position-x: -156px; }
  &.lx { background-position-x: -182px; }
  &.nl { background-position-x: -208px; }
  &.pt { background-position-x: -234px; }
  &.se { background-position-x: -260px; }
  &.es { background-position-x: -286px; }
  &.sk { background-position-x: -312px; }
  &.cz { background-position-x: -338px; }
  &.ch { background-position-x: -364px; }
  &.uk { background-position-x: -390px; }
  &.us { background-position-x: -416px; }
}

/* media control elements */
.ctrl {
  display: inline-block;
  &:before {
    display: inline-block;
    float: left;
    padding: 2px 8px;
    font-family: @icon;
    text-align: center;
    color: #ffffff;
    background: @pink;
  }
  &.play:before { content: '\f04b'; }
  &.pause:before { content: '\f04c'; }
  &.stop:before { content: '\f04d'; }
  &.prev:before { content: '\f048'; }
  &.next:before { content: '\f051'; }
  &.backward:before { content: '\f04a'; }
  &.forward:before { content: '\f04e'; }
  &.mute:before { content: '\f028'; }
  &.unmute:before { content: '\f026'; }
}



/* floatings / alignments */
.f-right {
  float: right;
}

.f-left {
  float: left;
}

.clear {
  clear: both;
  width: 100%;
  font-size: 0;
  line-height: 0;
  &.raute {
    width: auto;
    margin: 0 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    &:after {
      display: block;
      height: 20px;
      margin: 0 auto;
      background: url('../images/clear-raute.png') center no-repeat transparent;
      background-size: cover;
      content: ' ';
    }
  }
  &.red {
    padding-top: 5px;
    padding-bottom: 5px;
    &:after {
      display: block;
      height: 4px;
      margin: 0 auto;
      content: ' ';
      background-color: @pink;
    }
  }
  &.decorative {
    position: relative;
    display: block;
    width: auto;
    margin: 0 5px;
    height: 32px;
    text-align: center;
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: '';
    }
    &:before {
      top: 14px;
      left: 0;
      right: 0;
      bottom: 14px;
      background: radial-gradient(ellipse at center, #d9d1c6 0%, #f5f5f5 50%, #f5f5f5 100%);
      border-radius: 100%;
    }
    &:after {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: @white;
      background-image: url('../images/favicon.png');
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      left: calc(50% - 17px);
    }
  }
  &.text {
    .flex-rows();
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    &:before,
    &:after {
      .flex-item-adjust();
      height: 1px;
      content: '';
      background-color: @darkgrey;
      margin-top: 6px;
    }
    &:before {
      margin-right: 5px;
    }
    &:after {
      margin-left: 5px;
    }
  }
}
@media screen and (min-width: 769px) {
  .clear {
    &.raute,
    &.red {
      margin: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      &:after {
        margin: 0 10px;
      }
    }
    &.decorative {
      margin: 0 10px;
      height: 48px;
      &:before {
        top: 21px;
        bottom: 21px;
      }
      &:after {
        width: 48px;
        height: 48px;
        background-size: 30px;
        left: calc(50% - 24px);
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .clear {
    &.mobile {
      clear: none;
      display: none;
    }
  }
}

.a-center {
  text-align: center;
}

.a-left {
  text-align: left;
}

.a-right {
  text-align: right;
}
.nobr {
  white-space: nowrap;
}

/* forms */
.input-box {
  position: relative;
  display: block;
  margin: 0 0 15px;
}
.toggle-password {
  display: inline-block;
  &:before {
    font-family: @icon;
    font-size: 13px;
    line-height: 20px;
    content: '\f06e';
  }
  &.hide:before {
    content: '\f070';
  }
}
input[type=text],
input[type=search],
input[type=tel],
input[type=number],
input[type=password],
input[type=email] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 4px 5px 2px;
  border: 1px solid @mediumgrey;
  background-color: @white;
  .sans-serif-regular();
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

/* Modern Browsers */

input[type=checkbox],
input[type=radio] {
  float: left;
  margin: 4px 10px 0 0;
}

label {
  display: block;
  box-sizing: border-box;
  font-size: 90%;
  line-height: 20px;
}

select {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  width: 100%;
  padding: 8px 15px 8px 5px;
  .sans-serif-regular();
  font-size: 11px;
  background: none;
  &::-ms-expand {
    display: none;
  }
  option {
    .sans-serif-regular();
  }
}
.select-small select {
  padding: 4px 15px 4px 5px;
  font-size: 11px;
  line-height: 10px;
  vertical-align: text-top;
}

.select-box,
.input-box.select,
.input-box.select-small {
  position: relative;
  display: block;
  width: 100%;
  min-width: 90px;
  border: 1px solid @mediumgrey;
  background: @white;
  &.highlight {
    border-color: @pink;
    -webkit-box-shadow: 0px 0px 2px @pink;
    -moz-box-shadow: 0px 0px 2px @pink;
    box-shadow: 0px 0px 2px @pink;
  }
}

.select-box:after,
.input-box.select:after,
.input-box.select-small:after {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  display: inline-block;
  font-family: @icon;
  content: '\f0dc';
  color: @darkgrey;
}
.input-box.select-small:after {
  top: 1px;
}

textarea {
  display: block;
  min-width: 200px;
  box-sizing: border-box;
  border: 1px solid @mediumgrey;
  background: @white;
}

.input-box.radio {
  input {
    position: absolute;
    top: 0;
    left: 0;
  }
  label {
    display: block;
    padding-left: 25px;
  }
}

/* NEW-Style for input[type=text] input[type=password] */
.dynamic-label {
  .input-box {
    position: relative;
    display: block;
    box-sizing: border-box;
    margin: 0 0 20px;
    &.input-box-button {
      background-color: transparent;
    }
    &.checkbox {
      padding-left: 30px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      line-height: 21px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 1px solid @mediumgrey;
        &:after {
          content: "";
          position: absolute;
          opacity: 0.1;
        }
      }
      input:checked ~ .checkmark:after {
        opacity: 1;
      }
      .checkmark:after {
        width: 100%;
        font-family: @icon;
        content: '\f00c';
        font-size: 12px;
        line-height: 20px;
        text-align: center;
      }
      &.longer-label-text {
        line-height: 20px;
        .checkmark {
          top: 4px;
        }
      }
    }
    &.select {
      border: 1px solid rgba(0, 0, 0, 0.2);
      min-width: auto;
      &:after {
        top: 13px;
        right: 10px;
        content: '\f0dc';
        color: #b4146e;
      }
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=password] {
      position: relative;
      display: block;
      z-index: 3;
      margin: 0;
      padding: 18px 10px 4px 10px;
      transition: 0.2s ease all;
      background-color: @white;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      font-size: 13px;
      line-height: 16px;
      max-width: none;
      &.validation-failed {
      }
      &:focus ~label {
        font-size: 10px;
        line-height: 18px;
        z-index: 3;
      }
    }

    label {
      display: block;
      position: absolute;
      top: 1px;
      z-index: 4;
      padding: 0 10px;
      .sans-serif-regular();
      font-size: 10px;
      line-height: 18px;
      transition: 0.2s ease all;
    }
    select {
      height: 38px;
      padding: 18px 10px 2px;
      font-size: 12px;
    }

    .toggle-password {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
      width: 38px;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      text-align: center;
      &:before {
        line-height: 40px;
      }
    }
    &.select {
      input[type=text],
      input[type=email],
      input[type=password] {
        padding: 18px 10px 4px 10px;
      }
      label {
        font-size: 10px;
        line-height: 18px;
        z-index: 4;
      }
    }
    input[type=text],
    input[type=email],
    input[type=password] {
      &:not(:placeholder-shown) {
        padding: 18px 10px 4px 10px;
        &+label {
          font-size: 10px;
          line-height: 18px;
          z-index: 4;
        }
      }
      &:not(:-ms-input-placeholder) {
        padding: 18px 10px 4px 10px;
        &+label {
          font-size: 10px;
          line-height: 18px;
          z-index: 4;
        }
      }
    }

    // autofill detection hack
    @keyframes onAutoFillStart {  from { background-color: white; }  to { background-color: white; }}
    @keyframes onAutoFillCancel {  from { background-color: white; }  to { background-color: white; }}
    input:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 500s ease-in-out 0s;
    }


    &-recaptcha {
      height: auto;
      text-align: center;
      .g-recaptcha {
        display: inline-block;
        div {
          max-width: 100%;
          width: 100% !important;
          iframe {
            width: 100% !important;
          }
        }
      }

      .validation-advice {
        position: absolute;
        left: 50%;
        top: 100%;
        z-index: 2;
        padding: 10px 10px 8px;
        font-size: 13px;
        line-height: 16px;
        margin-left: -100px;
        &:before {
          position: absolute;
          top: -16px;
          left: 50%;
          border: 8px solid transparent;
          border-top: 8px solid @lightred;
          content: ' ';
          margin-left: -10px;
        }
      }
    }
  }
  .validation-advice {
    position: relative;
    left: 0;
    top: 100%;
    z-index: 3;
    margin: 8px 0 10px;
    padding: 10px 10px 8px;
    background-color: @lightred;
    border: 1px solid @lightred;
    .sans-serif-regular();
    font-size: 13px;
    line-height: 16px;
    color: @red;
    &:before {
      position: absolute;
      top: -16px;
      left: 7px;
      border: 8px solid transparent;
      border-bottom: 8px solid @lightred;
      content: ' ';
    }
    &.checkbox {
      .validation-advice {
        left: -30px;
      }
    }
  }
  .input-advice {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 5;
    margin-right: 10px;
    padding: 2px 30px 2px 7px;
    background-color: @lightred;
    border: 1px solid @lightred;
    .sans-serif-regular();
    color: @red;
    .close {
      top: 0;
      right: 0;
    }

  }
  button:not(.frc-button), .btn {
    display: block;
    width: 100%;
    height: 38px;
  }
  .input-box.checkbox {
    input[type=checkbox] {
      height: 20px;
    }
    label {
      position: relative;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
    }
  }
}

#newsletter-registration-mistake-hint {
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid @lightred;
  background-color: @lightred;
  .sans-serif-regular();
  font-size: 12px;
  color: @red;
  a {
    color: @red;
    text-decoration: underline;
  }
}

/* address-form */
.address-form {
  font-size: 13px;
}

.address-form .field,
.address-form .fields {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

.address-form .field.name-gender,
.address-form .field.name-prefix {
  width: 26%;
  margin-right: 4%;
  float: left;
}

.address-form .field.street {
  width: 70%;
  float: left;
}

.address-form .field.streetnumber {
  width: 26%;
  float: right;
}

.address-form .field.postalcode {
  width: 26%;
  float: left;
}

.address-form .field.city {
  width: 70%;
  float: right;
}

/* tables */
table,
.table {
  width: 100%;
  border-collapse: collapse;
}

.table {
  display: table;
}
.tr {
  display: table-row;
}
.th, .td {
  display: table-cell;
}

.th {
  .sans-serif-bold();
}

.v-top {
  vertical-align: top;
}

.v-middle {
  vertical-align: middle;
}

.v-bottom {
  vertical-align: bottom;
}

.data-table {
  text-align: left;
  font-size: 12px;
}

.data-table .thead {
  display: none;
}

.data-table th,
.data-table td,
.data-table .th,
.data-table .td {
  padding: 3px 10px 3px 0;
  border-bottom: 1px solid #dcdad9;
  vertical-align: top;
}

.data-table .data-table-title-row {
  background: #efefef;
  .sans-serif-bold();
  text-align: center;
  border-top: 1px solid #dcdad9;
}

/* lists etc */
.disc,
.square,
.dice,
.decimal,
.upper-roman,
.upper-latin,
.lower-roman,
.lower-latin {
  margin: 20px 0;
}

ul.disc > li {
  list-style: disc;
  margin-left: 10px;
  width: auto;
}

ul.circle > li {
  list-style: circle;
  margin-left: 10px;
  width: auto;
}

ul.square > li {
  list-style: square;
  margin-left: 10px;
  width: auto;
}

ul.dice li:before {
  display: inline-block;
  margin-right: 5px;
  font-family: @icon;
  font-size: 8px;
  content: '\f0c8';
}

ul.icon_yes li:before {
  display: inline-block;
  margin-right: 5px;
  font-family: @icon;
  font-size: 14px;
  content: '\f00c';
  color: @green;
}

ul.icon_no li:before {
  display: inline-block;
  margin-right: 5px;
  font-family: @icon;
  font-size: 14px;
  content: '\f00d';
  color: @red;
}

ol.decimal > li {
  list-style: decimal;
  margin-left: 10px;
  width: auto;
}

ol.upper-roman > li {
  list-style: upper-roman;
  margin-left: 10px;
  width: auto;
}

ol.upper-latin > li {
  list-style: upper-latin;
  margin-left: 10px;
  width: auto;
}

ol.lower-roman > li {
  list-style: upper-roman;
  margin-left: 10px;
  width: auto;
}

ol.lower-latin > li {
  list-style: upper-latin;
  margin-left: 10px;
  width: auto;
}

.disc.nav li,
.circle.nav li,
.square.nav li,
.dice.nav li {
  line-height: 20px;
  padding: 5px 0;
}

.disc.nav li:before,
.circle.nav li:before,
.square.nav li:before,
.dice.nav li:before {
  color: @pink;
}

/* use for structured text page index e.g. privacy policy */
.table-of-content {
  li {
    margin: 0 0 20px;
    line-height: 20px;
    a {
      .sans-serif-bold();
    }
    li {
      margin: 0;
      padding-left: 15px;
      a {
        .sans-serif-regular();
      }
    }
  }
}

.icon-list {
  position: relative;
  li {
    position: relative;
    box-sizing: border-box;
    padding-left: 2em;
    &:before {
      position: absolute;
      display: block;
      left: 0.5em;
      font-family: @icon;
    }
    &.icon-allowed:before {
      content: '\f00c';
      color: @green;
    }
    &.icon-forbidden:before {
      content: '\f00d';
      color: @red;
    }
  }
}

/* Messages */
.messages {
  box-sizing: border-box;
  margin: 0 10px 20px;
  overflow: auto;
  & > li {
    position: relative;
    margin: 0 0 10px;
    padding: 10px 20px;
    border-radius: 8px;
    .sans-serif-bold();
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    a {
      text-decoration: underline;
    }
  }
}

.right-col .messages {
  margin: 0 0 20px;
  padding: 0 10px;
}

.error-msg {
  background-color: @lightred;
  color: darken(@red, 10%);
}

.success-msg {
  background-color: @lightgreen;
  color: darken(@green, 10%);
}

.note-msg,
.notice-msg {
  background-color: @lightblue;
  color: darken(@blue, 10%);
}
@media screen and (min-width: 769px) {
  .messages {
    margin: 0 20px 20px;
  }
}
@media screen and (min-width: 1200px) {
  .messages {
    max-width: 1160px;
    margin: 0 auto 20px;
  }
}


.item-msg.error,
.item-msg.success,
.item-msg.note {
  text-align: left;
  .sans-serif-bold();
  font-size: 12px;
  line-height: 15px;
  &:before {
    display: inline;
    padding-right: 5px;
    font-family: @icon;
    font-size: 11px;
    line-height: 15px;
  }
  a {
    text-decoration: underline;
  }
}

.item-msg.error {
  color: @red;
  &:before {
    content: '\f00d';
  }
}

.item-msg.success {
  color: @green;
  &:before {
    content: '\f00c';
  }
}

.item-msg.note {
  color: @blue;
  &:before {
    content: '\f12a';
  }
}

.mailchimp-widget-result {
  padding: 10px;
}

.mailchimp-widget-result.error {
  color: @red;
}

.mailchimp-widget-result.info {
  color: #0070fa;
}

.mailchimp-widget-result.success {
  color: #007314;
}

.messages {
  li {
    display: block;
    box-sizing: border-box;
    clear: both;
    &:before {
      float: left;
    }
    ul {
      li {
        margin: 0;
        padding: 0;
        clear: none;
        background-color: transparent;
      }
    }
  }
}

.icon-availability {
  display: inline-block;
  margin: 0;
  font-family: 'FontAwesome';
  font-weight: normal;
  &.icon-in-stock:before {
    color: @green;
    content: '\f00c';
  }
  &.icon-out-of-stock:before {
    content: '\f00d';
    color: @red;
  }
}

/* flags */
.price-box .flag,
.flags .flag {
  display: inline-block;
  float: right;
  height: 16px;
  width: auto;
  padding: 1px 6px;
  margin: 0 0 0 5px;
  border-style: solid;
  border-width: 1px;
  background-color: @white;
  background-image: none;
  background-repeat: no-repeat;
  border-radius: 3px;
  strong {
    float: left;
    .sans-serif-regular();
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
  }
}

.flags .last-flag {
  background-color: @pink;
  border-color: @pink;
  color: @white;
}
.flags .new-flag {
  background-color: @green;
  border-color: @green;
  color: @white;
}
.flags .noflag {
  display: none;
}

.price-box .percentage-flag,
.flags .percentage-flag {
  border-color: @pink;
  color: @pink;
  &.minus25,
  &.minus30,
  &.minus40,
  &.minus50,
  &.minus51,
  &.minus52,
  &.minus60 {
    border-color: @pink;
    background-color: @pink;
    color: @white;
  }
  &.minus90 {
    display: none;
  }
  /* designer outlet
  &.amayani,
  &.annette,
  &.de-melo,
  &.dallas-prince-designs,
  &.cirari,
  &.d-vyere,
  &.ornaments-by-de-melo,
  &.suhana {
    position: relative;
    background-color: #585856;
    border-color: #585856;
    color: #fff;
    strong {
      .sans-serif-bold();
    }
    &:before {
      position: relative;
      top: -1px;
      display: inline-block;
      width: 38px;
      padding-right: 5px;
      float: left;
      .sans-serif-bold();
      font-size: 8px;
      line-height: 8px;
      text-align: left;
      content: 'DESIGNER';
      color: #ccc6bf;
    }
    &:after {
      position: absolute;
      top: 8px;
      left: 5px;
      display: inline-block;
      width: 38px;
      .sans-serif-bold();
      font-size: 8px;
      line-height: 8px;
      text-align: left;
      content: 'OUTLET';
      color: #fff;
    }
  }
  */
}

.amount-msg {
  display: block;
  font-size: 13px;
  strong {
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 0 8px;
    border-radius: 3px;
    background-color: @blue;
    color: @white;
    .sans-serif-bold();
  }
  span {
    .sans-serif-regular();
    white-space: nowrap;
  }
}

.vorfreude {
  .price-box,
  .flags {
    .percentage-flag {
      border-color: @pink;
      background-color: @pink;
      color: @white;
      &:before {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 24px;
        padding-right: 5px;
        float: left;
        .sans-serif-bold();
        font-size: 8px;
        line-height: 8px;
        text-align: left;
        content: 'TOP DEAL';
      }
    }
  }
}

.summer-sale {
  .price-box,
  .flags {
    .flag.summer-sale {
      border-color: @pink;
      background-color: @pink;
      color: @white;
      &:before {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 24px;
        padding-right: 5px;
        float: left;
        .sans-serif-bold();
        font-size: 8px;
        line-height: 8px;
        text-align: left;
        content: 'TOP DEAL';
      }
    }
  }
}

.blackfriday {
  .price-box,
  .flags {
    .percentage-flag {
      border-color: @black;
      background-color: @black;
      color: #fff;

      &:before {
        position: relative;
        top: 0;
        display: inline-block;
        width: 25px;
        padding-right: 5px;
        float: left;
        .sans-serif-bold();
        font-size: 7px;
        line-height: 8px;
        text-align: left;
        content: 'BLACK DEAL';
      }
    }
  }
}
/*
.blackday {
  .price-box,
  .flags {
    .percentage-flag {
      border-color: @black;
      background-color: @black;
      color: #fff;
      &:before {
        position: relative;
        top: 0;
        display: inline-block;
        width: 25px;
        padding-right: 5px;
        float: left;
        .sans-serif-bold();
        font-size: 7px;
        line-height: 8px;
        text-align: left;
        content: 'BLACK DEAL';
      }
    }
  }
}
*/
.blackday {
  .price-box,
  .flags {
    .percentage-flag {
      &.minus50,
      &.minus51,
      &.minus52,
      &.minus60,
      &.minus70 {
        border-color: @black;
        background-color: @black;
        color: #fff;
        &:before {
          position: relative;
          top: 0;
          display: inline-block;
          width: 25px;
          padding-right: 5px;
          float: left;
          .sans-serif-bold();
          font-size: 7px;
          line-height: 8px;
          text-align: left;
          content: 'BLACK DEAL';
        }
      }
    }
  }
}


.price-box,
.flags {
  .new-flag.super-deal,
  .noflag.super-deal,
  .percentage-flag.super-deal {
    display: inline-block;
    /*
    border-color: @black;
    background-color: @black;
    color: @lightpink;
    border-color: rgba(185,220,215,1);
    background-color: rgba(185,220,215,1);
    color: @black;
    */
    border-color: #EB5F9B;
    background-color: #EB5F9B;
    color: @white;
    &:before {
      position: relative;
      top: 0;
      display: inline-block;
      width: 19px;
      padding-right: 5px;
      float: left;
      .sans-serif-bold();
      font-size: 8px;
      line-height: 8px;
      text-align: left;
      content: 'TOP DEAL';
    }
  }
}
.it .flags {
  .new-flag.super-deal,
  .noflag.super-deal,
  .percentage-flag.super-deal {
    &:before {
      width: 28px;
      content: 'MEGA AFFARE';
    }
  }
}


.bday .flags .new-flag {
  padding: 2px 6px;
  border-width: 0;
  border-color: transparent;
  background-color: transparent;
  background-image: url('../images/new-flag-bg-bday2023.jpg');
  background-size: cover;
  color: @white;
}

.country-flag {
  display: inline-block;
  width: 24px;
  height: 18px;
  background-image: url('../images/flags.png');
  .shadow();
}

.country-flag.de {
  background-position: 0px 0;
}

.country-flag.at {
  background-position: -26px 0;
}

.country-flag.eu {
  background-position: -52px 0;
}

.country-flag.fr {
  background-position: -78px 0;
}

.country-flag.it {
  background-position: -104px 0;
}

.country-flag.dk {
  background-position: -130px 0;
}

.country-flag.be {
  background-position: -156px 0;
}

.country-flag.lx {
  background-position: -182px 0;
}

.country-flag.nl {
  background-position: -208px 0;
}

.country-flag.pt {
  background-position: -234px 0;
}

.country-flag.se {
  background-position: -260px 0;
}

.country-flag.es {
  background-position: -286px 0;
}

.country-flag.sk {
  background-position: -312px 0;
}

.country-flag.cz {
  background-position: -338px 0;
}

.country-flag.ch {
  background-position: -364px 0;
}

.country-flag.uk {
  background-position: -390px 0;
}

.country-flag.us {
  background-position: -416px 0;
}

.block-advantages ul li {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.block-advantages ul li p {
  padding: 0 0 0 50px;
  min-height: 40px;
}

.block-advantages ul li .icon {
  position: relative;
  background-color: @pink;
  display: block;
  height: 40px;
  width: 40px;
  float: left;
}

.block-advantages ul li .icon:before {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  margin: 10px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  color: @white;
}

.block-advantages ul li .icon.ni:before {
  margin: 9px;
  border-color: @white;
  border-width: 1px;
  border-style: solid;
  line-height: 22px;
  content: 'Ni';
}

.block-advantages ul li .icon.ni:after {
  display: block;
  position: absolute;
  top: 19px;
  left: 9px;
  width: 20px;
  transform: rotate(135deg);
  border-bottom: 1px solid @white;
  font-size: 0;
  line-height: 0;
  content: ' ';
}

.block-advantages ul li .icon.eur:before {
  font-size: 16px;
  content: '€';
}

.block-advantages ul li .icon.gems:before {
  width: 40px;
  margin: 10px 0;
  content: '+900';
  font-size: 10px;
}

.block-advantages ul li .icon.return:before {
  font-family: @icon;
  font-size: 14px;
  content: '\f021';
}

.block-advantages ul li .icon.cert:before {
  font-family: @icon;
  font-size: 14px;
  content: '\f1ea';
}

.block-advantages ul li .icon.ring-size:before {
  margin: 9px;
  border-color: @white;
  border-width: 1px;
  border-style: solid;
  font-family: @icon;
  font-size: 14px;
  content: '\f07e';
}

.current {
}

/* blocks */
.block {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  float: left;
  margin: 0 0 10px;
  padding: 0 10px 10px;
}

footer .block {
  margin: 0;
}

.block .block-title {
  position: relative;
  margin: 0 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  line-height: 20px;
  p.info {
    .sans-serif-bold();
    font-size: 12px;
  }
}

footer .block .block-title {
  padding: 0 0 10px;
}

.block .block-content {
  position: relative;
}

.sidebar .block,
.block-sidenav {
  background: @lightgrey;
}
.sidebar {
  position:relative;
}

.block-sidenav li {
  margin: 0;
  padding: 10px 0 0;
}

.media-box.f-left,
.media-box.f-right,
.media-box.a-center {
  margin: 10px;
}

.media-box p {
  font-style: italic;
  color: #606060;
  margin: 0;
  padding: 0;
}

.box {
  position: relative;
  box-sizing: border-box;
}

.box .box-title {
  margin: 0 0 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid #dcdad9;
}

.box.box-grey {
  padding: 20px;
  background: #f7f7f7;
}

.box.box-green {
  padding: 20px;
  margin: 0 5px;
  background: @lightgreen;
}

.border-box {
  box-sizing: border-box;
  padding: 20px;
  margin: 0 0 20px;
  border: 1px solid @darkgrey;
  background-color: #f7f7f7;
}

.border-box p:last-child {
  margin: 0;
}


.main-wrapper {
  background: @white;
}

#android-app-banner {
  display: none;
}
.touchevents {
  #android-app-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    box-sizing: border-box;
    background-color: @lightgrey;
    z-index: 16;
    overflow: hidden;
    .tr {
      display: table-row;
    }
    .td {
      display: table-cell;
      padding: 10px 0;
      vertical-align: middle;
    }
    .td-close {
      width: 20px;
      padding: 10px 5px;
      .close-app-banner {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        cursor: pointer;
        &:before,
        &:after {
          display: block;
          width: 9px;
          height: 9px;
          content: ' ';
          position: absolute;
        }
        &:before {
          top: 0;
          left: 1px;
          border-right: 1px solid @mediumgrey;
          border-bottom: 1px solid @mediumgrey;
        }
        &:after {
          bottom: 1px;
          right: 0;
          border-left: 1px solid @mediumgrey;
          border-top: 1px solid @mediumgrey;
        }
      }
    }
    .td-logo {
      width: 60px;
      .app-logo-container {
        display: block;
        width: 60px;
        height: 60px;
        box-sizing: border-box;
        border: 1px solid @mediumgrey;
        border-radius: 12px;
        background-color: @white;
        img {
          width: 50px;
          height: 50px;
          margin: 5px;
        }
      }
    }
    .td-text {
      .title {
        display: block;
        padding: 0 10px;
        .sans-serif-bold();
        font-size: 12px;
      }
      .subtitle {
        display: block;
        padding: 0 10px;
        font-size: 10px;
      }
      .stars {
        display: block;
        padding: 0 10px;
        color: @yellow;
      }
    }
    .td-button {
      .btn {
        font-size: 10px;
        line-height: 10px;
        padding: 10px;
        white-space: nowrap;
      }
    }
  }
  .app-banner-visible {
    #android-app-banner {
      display: table;
    }
  }
}



.userlike {
  #userlike {
    a.userlike-button-big {
      bottom: 0;
      transition: bottom 1s;
    }
    a.userlike-button-bubble {
      bottom: 0;
      transition: bottom 1s;
    }
  }
}
footer {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 0 !important;
}

/* main grid */
.main-container .main {
  position: relative;
}

.main-container .content-header {
  position: relative;
  text-align: center;
}
.main-container .content-header h1 {
  margin: 0 0 10px;
  padding: 10px 5px 0;
}

/* col-sets */
.col-set,
.col-2-set,
.col-3-set,
.col-4-set,
.col-5-set,
.col-6-set {
  box-sizing: border-box;
  width: 100%;
  padding: 0 5px;
}

.col-set .col,
.col-2-set .col,
.col-3-set .col,
.col-4-set .col,
.col-5-set .col,
.col-6-set .col {
  box-sizing: border-box;
  width: 100%;
  padding: 0 5px;
  position: relative;
  float: left;
}


/**/
.active, .open {
  z-index: 3;
}

.closing-layer {
  display: none;
}

.closing-layer.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: transparent;
}

.cookies-overlay {
  position: fixed;
  top: auto;
  bottom: 0;
  width: 100%;
  z-index: 3000000000;
  background: none;
  .cookies-overlay-content-wrapper {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: visible;
  }
  .cookies-overlay-content {
    position: relative;
    box-sizing: border-box;
    max-width: 500px;
    max-height: 100%;
    display: block;
    overflow: auto;
    margin: 0 auto;
    padding: 10px;
    background-color: @white;
    color: @black;
    text-align: center;
    .sticky-element-shadow();
  }
  .info {
    font-size: 11px;
    line-height: 13px;
  }
  .settings {
    text-align: left;
    label {
      input[type=checkbox]{
        width: 13px;
        height: 13px;
        margin: 0 10px 0 0;
      }
    }
    p {
      width: 100%;
      margin: 0 0 10px;
      padding: 0 0 10px;
      border-bottom: 1px solid @lightgrey;
      font-size: 11px;
      line-height: 13px;
      &:first-child {
        border-top: 1px solid @lightgrey;
      }
      span {
        display: block;
      }
    }
  }
  .button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
  }
  a {
    color: @black;
    text-decoration: underline;
  }
  #settings-toggle-2 {
    display: block;
    margin: 10px auto;
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .btn {
    display: block;
    font-size: 11px;
    margin: 0;
    padding: 7px 0 5px;
    text-decoration: none;
    &.white {
      width: 100%;
      margin: 0 0 10px;
      background-color: @white;
      border-color: @mediumgrey;
      color: @darkgrey;
    }
  }
}
@media screen and (min-width: 360px){
  .cookies-overlay {
    .info {
      font-size: 12px;
      line-height: 15px;
    }
    .settings {
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
@media screen and (min-width: 540px){
  .cookies-overlay {
    .cookies-overlay-content-wrapper {
      left: 50%;
      width: 500px;
      margin-left: -250px;
    }
  }
}
@media screen and (min-width: 1024px) and (orientation: portrait){
  .cookies-overlay {
    .cookies-overlay-content-wrapper {
      bottom: 20px;
    }
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape){
  .cookies-overlay {
    top: auto;
    bottom: 0;
    height: 0;
    .cookies-overlay-content {
      margin: 25px auto;
    }
    &.configure {
      top: auto;
      .cookies-overlay-content-wrapper {
        top: auto;
      }
    }
  }
}
@media screen and (min-width: 1280px) and (orientation: landscape){
  .cookies-overlay {
    top: auto;
    bottom: 0;
  }
}

/* scroll to top */
.scroll-to-top {
  display: none;
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 300;
  padding: 5px;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
  font-size: 9px;
  line-height: 150%;
  cursor: pointer;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  transition: background 0.5s;
}

.scroll-to-top:hover {
  background: rgba(0, 0, 0, 0.15);
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  transition: background 0.5s;
}

.scroll-to-top:before {
  display: inline-block;
  width: 100%;
  font-family: @icon;
  content: '\f077';
}
@media screen and (min-width: 1280px){
  .scroll-to-top {
    bottom: 20px;
  }
}

/* blog slider */
#slider_wrapper {
  display: none;
  position: fixed;
  top: 150px;
  left: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;
  z-index: 10;
  &.show {
    left: auto;
    right: 0;
    .author {
      background-image: url('../images/authors.jpg');
    }
  }
  img {
    display: block;
    max-width: 100%;
  }
}

.slide-switch {
  position: absolute;
  top: 100px;
  left: -22px;
  z-index: 100;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  .shadow();
}

#slider_wrapper.hide .slide-switch {
}

.slide-switch .btn {
  font-size: 10px;
  line-height: 20px;
  padding: 0 14px;
}

.slide-content {
  position: relative;
  top: -40px;
  z-index: 10005;
  padding: 15px;
  background: #f7f7f7;
  border: 1px solid #E2E2E2;
  .shadow();
}

.it .slide-content {
  top: -90px;
}

/* blog preview */
.slide-content .block-feed-reader .author {
  display: inline-block;
  float: left;
  width: 50px;
  height: 50px;
  margin: 5px 15px 10px 0;
  text-indent: -9999px;
  background-image: url('../images/authors.jpg');
  background-size: 100%;
  -ms-background-position-x: 0;
  background-position-x: 0;
  background-repeat: no-repeat;
  &#author-veichler {
    -ms-background-position-y: 0;
    background-position-y: 0;
  }
  &#author-cuini.amelio-ortiz,
  &#author-cuini,
  &#author-camelio {
    -ms-background-position-y: -50px;
    background-position-y: -50px;
  }
  &#author-eweierich {
    -ms-background-position-y: -100px;
    background-position-y: -100px;
  }
  &#author-slatini {
    -ms-background-position-y: -150px;
    background-position-y: -150px;
  }
  &#author-fzerbini {
    -ms-background-position-y: -200px;
    background-position-y: -200px;
  }
  &#author-Mkessler {
    -ms-background-position-y: -250px;
    background-position-y: -250px;
  }
  &#author-simona {
    -ms-background-position-y: -300px;
    background-position-y: -300px;
  }
  &#author-rvos {
    -ms-background-position-y: -350px;
    background-position-y: -350px;
  }
  &#author-fxthiebaud {
    -ms-background-position-y: -400px;
    background-position-y: -400px;
  }
  &#author-bmerolla {
    -ms-background-position-y: -450px;
    background-position-y: -450px;
  }
  &#author-audrey {
    -ms-background-position-y: -500px;
    background-position-y: -500px;
  }
  &#author-begona {
    -ms-background-position-y: -550px;
    background-position-y: -550px;
  }
  &#author-ahermansson {
    -ms-background-position-y: -600px;
    background-position-y: -600px;
  }
  &#author-syoung {
    -ms-background-position-y: -650px;
    background-position-y: -650px;
  }
}
.slide-content .block-feed-reader .feed-header {
  display: inline-block;
}

.slide-content .block-feed-reader .feed-header h3 {
  margin: 0 0 5px;
  font-size: 100%;
  line-height: 100%;
}

.slide-content .block-feed-reader .feed-header h5 {
  display: block;
  height: 40px;
  margin: 0;
  font-size: 100%;
  line-height: 20px;
}

.slide-content .block-feed-reader .feed-header .date {
  display: block;
  height: 20px;
  margin: 0;
  font-size: 100%;
  line-height: 20px;
}

.slide-content .block-feed-reader .feed-header .date {
  display: inline-block;
  margin: 0 0 5px;
  font-size: 90%;
  line-height: 100%;
}

.slide-content .block-feed-reader .feed-summary p {
  margin: 10px 0 0;
  font-size: 90%;
}

/* programm slider */
.block-preview h3 {
  font-size: 100%;
}

.block-preview table {
  width: 100%;
}

.block-preview td {
  font-size: 90%;
  vertical-align: top;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.block-preview td.time {
  font-size: 80%;
  font-weight: bold;
  color: @white;
  background: @pink;
  padding: 5px 10px;
  width: 40px;

}

.block-preview td.name {
  padding: 5px 10px;
}

.block-preview td.name img {
  float: left;
  margin: 0 10px 0 0;
}

/* overlay content mobile */
.overlay-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  z-index: 15;
  background: @white;
  -webkit-box-shadow: 0px 3px 9px #ccc;
  -moz-box-shadow: 0px 3px 9px #ccc;
  box-shadow: 0px 3px 9px #ccc;
}

.overlay-content-wrapper .mobile-content h2 {
  text-align: left;
}

.overlay-content-wrapper .mobile-content .close {
  display: none;
}

.max-width {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .max-width {
    position: relative;
  }
}


.page-title {
  padding: 0 5px;
}

/* PopUps */
.popup-fader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 16;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

.popup-content {
  position: relative;
  z-index: 17;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: @white;
}

.close.close-popup {
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.popup-title {
  margin: 0 0 20px;
  padding: 0 20px 10px 0;
  border-bottom: 4px solid @pink;
  text-align: left;
}

/* brand promos */

.brand-promo-2 {
  margin-bottom: 20px;
}

.brand-promo-2 ul {
  position: relative;
  padding-bottom: 50px;
}

.brand-promo-2 li {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 0;
}

.no-touchevents .brand-promo-2 ul:hover li:hover,
.brand-promo-2 li.active,
.no-touchevents .brand-promo-2 ul:hover li.active:hover {
  z-index: 2;
}

.no-touchevents .brand-promo-2 ul:hover li.active {
  z-index: 1;
}

.brand-promo-2 a {
  color: @pink;
  text-transform: uppercase;
  .sans-serif-regular();
  text-decoration: none;
}


.brand-promo-2 li.active a {
  background-color: @lightgrey;
  cursor: default;
}


.brand-promo-2 li.active {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
}

.brand-promo-2 li a {
  display: block;
  width: 33.33%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
}

.brand-promo-2 li.brand-promo-center a {
  left: 33.33%;
}

.brand-promo-2 li.brand-promo-right a {
  left: 66.66%;
}

.brand-promo-2 li img {
  display: block;
}
.brand-promo-2 {
  video {
    width: calc(100% - 10px);
    margin: 0 5px;
  }
  .cls-fix {
    position: relative;
    padding: 28.75% 20px 0;
  }
  .brand-promo-2-prosa {
    margin: 20px 0 0;
    padding: 0;
    h2 {
      text-align: center;
    }
    p {
      font-size: 15px;
      text-align: center;
    }
    .cls-fix {
      position: relative;
      padding: 59% 0 0;
    }
  }
  .more-link {
    display: none;
  }
  .col-2-set {
    .col {
      h2 {
        margin-top: 0;
      }
    }
  }
}
.no-touchevents .brand-promo-2 a:hover {
  background-color: @pink;
  color: @white;
}
.no-touchevents .brand-promo-2 li.active a:hover {
  color: @pink;
  background-color: @lightgrey;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .brand-promo-2 li a {
    font-size: 12px;
  }
  .brand-promo-2-prosa {
    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 896px) {
  .brand-promo-2 li a {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .brand-promo-2 {
    margin-bottom: 0;
    ul {
      padding-bottom: 0;
      li {
        display: none;
        a {
          display: none;
        }
        &.active {
          position: relative;
          display: block;
        }
      }
    }
    .more-link {
      display: block;
      position: relative;
      z-index: 2;
      width: 100%;
      height: 60px;
      margin: -30px 0 0;
      font-size: 0;
      line-height: 30px;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.02) 5%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.02) 5%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.02) 5%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
      .more {
        display: block;
        padding-top: 30px;
        &:after {
          padding: 5px 15px;
          border: 1px solid @lightgrey;
          font-family: @icon;
          font-size: 12px;
          content: '\f078';
        }
      }
      .less {
        display: block;
        padding-top: 30px;
        &:after {
          padding: 5px 15px;
          border: 1px solid @lightgrey;
          font-family: @icon;
          font-size: 12px;
          content: '\f077';
        }
      }
    }
    .brand-promo-2-prosa {
      position: relative;
      z-index: 1;
      max-height: 60px;
      margin: 0;
      padding: 10px 0 0;
      overflow: hidden;
      -webkit-transition: max-height 0.75s ease 0.2s;
      -moz-transition: max-height 0.75s ease 0.2s;
      transition: max-height 0.75s ease 0.2s;
      p {
        font-size: 12px;
      }
      &.prosa-open {
        max-height: 900px;
        -webkit-transition: max-height 0.75s ease 0.2s;
        -moz-transition: max-height 0.75s ease 0.2s;
        transition: max-height 0.75s ease 0.2s;
        ~ .more-link {
          background: none;
        }
      }
    }
  }
  .brand-promo-2 {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 769px) {
  .brand-promo-2 {
    video {
      width: calc(100% - 20px);
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  .brand-promo-2-prosa {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 12px;
    }
  }
}

/* dynamic ui */
.show-if-logged-in,
.show-if-logged-out,
.show-if-free-shipping-global, /* global in shop */
.show-if-free-shipping-webshop-only, /* global in shop */
.show-if-free-shipping-today, /* personal state customer */
.show-if-free-shipping-next-x, /* personal state customer */
.show-if-free-shipping-successive-buy, /* personal state customer */
.show-if-free-shipping-amount-x, /* personal state when cart satisfies min-free-shipping-amount  */
.show-if-free-shipping-not-free, /* not free */
.show-if-free-shipping-first-order, /* first order */
.show-if-free-shipping-possible-from-amount-x /* possible to reach min-free-shipping-amount */ {
  display: none !important;
}

@media screen and (min-width: 641px) {

  /* grid */
  .col-2-set .col,
  .col-3-set .col,
  .col-4-set .col,
  .col-5-set .col {
    width: 50%;
  }
  .col-3-set .col:first-child,
  .col-5-set .col:first-child {
    width: 100%;
  }
  .main-container .content-header {
    text-align: left;
  }
  .main-container .content-header h1 {
    padding: 20px 5px 10px;
  }
  .brand-promo-2 {
    .col-2-set {
      .col {
        padding: 0 0 0 5px;
        &:first-child {
          padding:  0 5px 0 0;
        }
      }
    }
  }

  .table {
    display: table;
    width: 100%;
    vertical-align: top;
    box-sizing: border-box;
    border-collapse: collapse;
  }
  .tr {
    display: table-row;
  }
  .th,
  .td {
    display: table-cell;
    vertical-align: top;
    box-sizing: border-box;
  }

  #btn_paypal {
    img {
      float: left;
      margin: 10px 25px;
    }
    .text {
      float: left;
      max-width: 300px;
      height: 60px;
      padding: 10px 25px;
    }
  }
  #btn_ideal {
    img {
      float: left;
      margin: 0 25px;
    }
    .text {
      float: left;
      max-width: 300px;
      height: 60px;
      padding: 10px 25px;
    }
  }
}

@media screen and (min-width: 769px) {


  .close {
    right: 10px;
  }

  /* grid */
  .col-set {
    padding: 0 10px;
  }

  .col-set .col {
    padding: 0 10px;
  }
  .main-container .content-header h1 {
    padding: 10px;
  }
  .brand-promo-2 {
    .col-2-set {
      .col {
        padding: 0 0 0 10px;
        &:first-child {
          padding:  0 10px 0 0;
        }
      }
    }
  }

  .page-title {
    padding: 0 10px;
  }

  .col-3-set .col,
  .col-6-set .col {
    box-sizing: border-box;
    width: percentage((1/3));
  }

  .col-4-set .col {
    box-sizing: border-box;
    width: percentage((1/4));
  }

  h1, .h1 {
    font-size: 135%;
  }

  h2, .h2 {
    font-size: 110%;
  }

  h3, .h3 {
    font-size: 100%;
  }

  h4, .h4 {
    font-size: 80%;
  }

  h5, .h5 {
    font-size: 80%;
  }

  .block-title h3,
  .block-title h5 {
    font-size: 110%;
  }

  blockquote {
    margin: 30px;
    font-size: 150%;
  }

  blockquote p {
    font-size: 12px;
  }

  .x-large-text {
    font-size: 300%;
  }

  /* col-sets */
  .col-3-set .col,
  .col-3-set .col:first-child {
    width: percentage((1/3));
  }

  .table {
    display: table;
    width: 100%;
    vertical-align: top;
  }

  .tr {
    display: table-row;
  }

  .th,
  .td {
    display: table-cell;
    vertical-align: top;
  }

  .data-table {
    font-size: 11px;
  }

  .data-table th,
  .data-table td {
    padding: 3px 5px;
  }

  .box.box-green {
    margin: 0 10px;
  }

  /* blog slider */
  #slider_wrapper {
    display: block;
  }

  /* PopUps */

  .popup-wrapper {
    top: 0;
  }

  .popup-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }

  .close.close-popup {
    top: 10px;
    right: 20px;
  }

  .popup-title {
    margin: -10px 0 20px;
  }
}

@media screen and (min-width: 1025px) {


  h1, .h1 {
    font-size: 150%;
  }

  h2, .h2 {
    font-size: 135%;
  }

  h3, .h3 {
    font-size: 105%;
  }

  h4, .h4 {
    font-size: 85%;
    text-transform: uppercase;
  }

  h5, .h5 {
    font-size: 85%;
  }

  blockquote p {
    font-size: 14px;
  }

  .x-large-text {
    font-size: 350%;
  }

  footer .block {
    padding: 0 0 10px;
  }

  /* col-sets */
  .col-2-set .col {
    padding: 0 10px;
  }

  .col-3-set .col {
    padding: 0 10px;
  }

  .col-4-set .col {
    width: percentage((1/4));
    padding: 0 10px;
  }

  .col-5-set .col {
    width: percentage((1/5));
    padding: 0 10px;
  }

  .col-6-set .col {
    width: percentage((1/6));
    padding: 0 10px;
  }
  .brand-promo-2 .col-2-set {
    .col {
      padding: 0 0 0 20px;
      &:first-child {
        padding:  0 20px 0 0;
      }
    }
  }



  .main-container.col-2-left .content-header h1 {
    margin: 0 0 0 percentage((1/4));
  }

  .main-container .main .left-col {
    box-sizing: border-box;
    width: percentage((1/4));
    float: left;
  }

  .main-container .main .right-col {
    box-sizing: border-box;
    width: 75%;
    float: right;
  }

  .main-container.col-2-left .content-header h1 {
    margin-left: percentage((1/4));
    padding-left: 10px;
  }

  #slider_wrapper {
    top: 150px;
  }

  .decimal li {
    margin-left: 20px;
  }

  .block {
    margin: 0 0 20px;
  }
}

@media screen and (min-width: 1281px) {

  .clear.raute,
  .clear.red {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .clear.raute:after,
  .clear.red:after {
    margin: 0 auto;
    max-width: 1160px;
  }

  /* grid
  .col-set {
    padding: 0 15px;
  }

  .col-set .col,
  .col-2-set .col,
  .col-3-set .col,
  .col-4-set .col,
  .col-5-set .col,
  .col-6-set .col,
  .page-title {
    padding: 0 15px;
  }
  */
  h1, .h1 {
    font-size: 160%;
  }

  h2, .h2 {
    font-size: 130%;
  }

  h3, .h3 {
    font-size: 110%;
  }

  h4, .h4 {
    font-size: 90%;
    text-transform: uppercase;
  }

  h5, .h5 {
    font-size: 90%;
  }

  .block-title h3,
  .block-title h5 {
    font-size: 120%;
  }

  blockquote {
    max-width: 1000px;
    margin: 30px auto;
  }

  blockquote p {
    font-size: 14px;
  }

  .underline {
    text-align: center;
  }

  .underline:after {
    display: block;
    width: 130px;
    height: 4px;
    margin: 30px auto 40px;
    background-color: @pink;
    content: ' ';
  }

  .overline {
    text-align: center;
  }

  .overline:before {
    display: block;
    width: 130px;
    height: 4px;
    margin: 40px auto 30px;
    background-color: @pink;
    content: ' ';
  }

  .large-text {
    font-size: 250%;
    line-height: 100%;
  }

  .x-large-text {
    font-size: 400%;
    line-height: 100%;
  }

  h2.large-text,
  h2.x-large-text {
    .sans-serif-light();
  }

  h2.large-text strong,
  h2.x-large-text strong {
    .sans-serif-regular();
  }

  .main-container .content-header h1 {
    padding: 0 10px 10px;
  }

  /* blocks / boxes */
  .block {
    padding: 0 20px 25px;
  }

  footer .block {
    padding: 05px 0 15px;
  }

  .box-white {
    box-sizing: border-box;
    padding: 20px;
    background: @white;
    .shadow();
  }

  .box.box-green {
    margin: 0 10px;
  }

  .data-table {
    font-size: 12px;
  }

  /* onpage navigation */
  .op-nav ul {
    float: left;
    width: percentage((1/4));
  }

  #slider_wrapper {
    top: 230px;
  }

  .slide-switch .btn {
    padding: 4px 32px;
    line-height: 15px;
  }
}

div.x-adaptive-image {
  width:100%;
  height:1px;
}
span.x-adaptive-image {
  width:100%;
  height:1px;
  display:inline-block;
}
img.x-adaptive-image {
  width:100%;
  height:auto;
}
.x-image-container {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}



/* overlay */
.cart-progress {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background: url(../images/half-trans.png);
  z-index: 10000;
  -webkit-transition: background-color 0.35s ease;
  -moz-transition: background-color 0.35s ease;
  -ms-transition: background-color 0.35s ease;
  -o-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
  .css3-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
}


/* products grid items */
.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 30px;
  li {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0 5px;
    box-shadow: none;
    z-index: 1;
    text-align: left;
    .products-grid-item-wrapper {
      position: relative;
      box-sizing: border-box;
      display: block;
      width: 100%;
      .add-to-links {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 4;
        font-size: 0;
        .add-to-wishlist {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 24px;
          height: 24px;
          text-align: center;
          &:before {
            width: 24px;
            font-size: 20px;
            line-height: 24px;
            color: @mediumgrey;
          }
        }
      }

      .adding-to-wishlist {
        &:before {
          display: block;
          font-size: 48px;
          transition: font-size 0.2s;
        }
      }

      .image-box {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        padding-bottom: calc(100% + 36px);
        border: 1px solid @lightgrey;
        .product-image {
          position: absolute;
          top: 24px;
          width: calc(100% - 12px);
          margin: 0 6px;
          transition: transform .2s;
        }
        .flags {
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 3;
          .flag {
            width: auto;
            margin: 0 5px 0 0;
          }
          .birthday-product {
            display: none;
          }
        }
        .brand {
          position: absolute;
          bottom: 2px;
          left: 2px;
          z-index: 3;
          text-align: left;
          img {
            margin: 0;
            width: 60px;
            height: auto;
          }
          &.vitale-minerale {
            .sans-serif-regular();
            font-size: 9px;
            line-height: 11px;
            text-transform: uppercase;
            color: #000;
          }
        }
        .ringsize {
          position: absolute;
          bottom: 2px;
          right: 2px;
          z-index: 3;
          padding: 0 3px;
          color: @black;
          background-color: @lightgrey;
          border-radius: 15px;
          .sans-serif-medium();
          font-size: 11px;
          line-height: 15px;
          text-align: right;
        }
        .exclusive-web-product {
          display: block;
          float: right;
          height: 18px;
          padding: 0 4px 0 0;
          box-sizing: border-box;
          border-style: solid;
          border-width: 1px;
          border-color: @pink;
          border-radius: 3px;
          .sans-serif-regular();
          font-size: 10px;
          line-height: 18px;
          text-transform: uppercase;
          color: @pink;
          background-color: @white;
        }
      }
      .details-box {
        position: relative;
        padding-top: 5px;
        .product-name {
          box-sizing: border-box;
          width: 100%;
          padding: 0 0 2px;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.05em;
          color: @black;
        }
        .price-box {
          height: 24px;
          width: 100%;
          box-sizing: border-box;
          color: @black;
          .flag {
            display: none;
          }
          .label {
            display: none;
          }
          .price {
            position: relative;
            .sans-serif-bold();
            font-size: 15px;
            line-height: 24px;
          }
          .old-price-box {
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            padding-right: 3px;
            .price.old-price {
              text-decoration: line-through;
            }
            .strike {
              display: none;
            }
          }
          .new-price-box {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            padding-left: 3px;
            color: #b4146e;
          }
        }
        .materials, .creation, .sold {
          box-sizing: border-box;
          height: 24px;
        }
        .sold {
          font-size: 13px;
          line-height: 24px;
          text-transform: uppercase;
        }
        .materials {
          color: @black;
          white-space: nowrap;
          .base-material {
            display: inline-block;
            margin: 2px 2px 0 0;
            padding: 0 3px;
            float: left;
            border-radius: 3px;
            font-size: 11px;
            line-height: 17px;
          }
          .material-x {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 3px 3px 0 5px;
            float: left;
            border-radius: 100%;
          }
          .m-ys,
          .m-yg,
          .m-yw,
          .m-yr,
          .m-py,
          .m-x3g,
          .m-ba,
          .m-by {
            border: 1px solid #c1b185;
            background: #ede2c4;
          }
          .m-rs,
          .m-rg,
          .m-wr,
          .m-br,
          .m-pr,
          .m-br {
            border: 1px solid #c49797;
            background: #fed0d0;
          }
          .m-ss,
          .m-ws,
          .m-wg,
          .m-ps,
          .m-pt,
          .m-pw,
          .m-ti,
          .m-p9,
          .m-x2s,
          .m-bs,
          .m-bw {
            border: 1px solid #b1b0b4;
            background: #edecf1;
          }
          .m-2s {
            border: 1px solid #b1b0b4;
            background: #ecebf0;
            background: -moz-linear-gradient(left, #ecebf0 0%, #ecebf0 50%, #f4eee0 51%, #f4eee0 100%);
            background: -webkit-linear-gradient(left, #ecebf0 0%, #ecebf0 50%, #f4eee0 51%, #f4eee0 100%);
            background: linear-gradient(to right, #ecebf0 0%, #ecebf0 50%, #f4eee0 51%, #f4eee0 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ecebf0', endColorstr='#f4eee0', GradientType=1);
          }
        }
      }
      a {
        background: @white;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.is-on-wishlist {
      .products-grid-item-wrapper {
        .add-to-links {
          .add-to-wishlist {
            &:before {
              color: @pink;
            }
          }
        }
      }
    }
    .products-grid-item-wrapper {
      .add-to-links {
        .add-to-wishlist.is-on-wishlist {
          &:before {
            color: @pink;
          }
        }
      }
    }
    &:hover {
      .products-grid-item-wrapper {
        .image-box {
          border-color: @mediumgrey;
          .product-image {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .products-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 45px;
    li {
      .product-name {
        font-size: 11px;
        line-height: 20px;
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .products-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 769px) {
  .products-grid {
    li {
      padding: 0 10px;
    }
  }
}
@media screen and (min-width: 1025px) {
  .products-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .no-filter {
    .products-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
@media screen and (min-width: 1200px) {
  .products-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


/* wishlist / cart header etc */
.small-products-list {
  .item {
    position: relative;
  }
  .list-item-wrapper {
    .flex-rows();
    background-color: @white;
    .shadow();
    .product-image {
      .flex-item-grow();
      padding: 5px;
      img {
        width: 50px;
        height: 50px;
        max-width: none;
      }
    }
    .product-details {
      .flex-item-adjust();
      padding: 5px 0;
      color: @darkgrey;
      .product-name {
        display: block;
        margin: 0 0 5px;
        font-size: 11px;
        line-height: 15px;
        white-space: normal;
      }
      .product-sku {
        display: block;
        margin: 0 0 5px;
        .sans-serif-regular();
        font-size: 9px;
        line-height: 10px;
      }
      .product-variant {
        display: block;
        .sans-serif-regular();
        font-size: 9px;
        line-height: 10px;
      }
    }
    .product-price {
      .flex-item-grow();
      position: relative;
      padding: 5px;
      .sans-serif-regular();
      font-size: 10px;
      text-align: right;
      color: @darkgrey;
      strong {
        white-space: nowrap;
        .qty {
          font-size: 8px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .small-products-list {
    .list-item-wrapper {
      .product-image {
        padding: 10px;
      }
      .product-details {
        padding: 10px 0;
      }
      .product-price {
        padding: 10px;
      }
    }
  }
}


// horizontal slider

/* Trusted Shop / Trustpilot Custom Badge */
.ci-trustedshops {
  box-sizing: border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: @white;
  -webkit-box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  color: #000000;
  a .rating-stars,
  .rating-stars {
    font-size: 0;
    line-height: 0;
    .star {
      color: #ffdc0f;
    }
    .rating-stars-4 {
      .star:nth-child(5){
        color: #e5e5e5;
      }
    }
    .rating-stars-3 {
      .star:nth-child(4),
      .star:nth-child(5){
        color: #e5e5e5;
      }
    }
    .rating-stars-2 {
      .star:nth-child(3),
      .star:nth-child(4),
      .star:nth-child(5){
        color: #e5e5e5;
      }
    }
    .rating-stars-1 {
      .star:nth-child(2),
      .star:nth-child(3),
      .star:nth-child(4),
      .star:nth-child(5){
        color: #e5e5e5;
      }
    }
  }
  a {
    color: #000000;
  }
}
#trust-custom-badge {
  position: fixed !important;
  left: 10px;
  bottom: 10px;
  z-index: 4;
  transition: bottom 0.25s;
  display: block;
  width: 66px;
  box-sizing: content-box;
  padding: 8px 0;
  border-radius: 68px;
  text-align: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  &:hover {
    border-color: #ffdc0f;
  }
  .image-container {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .kaeuferschutz {
    display: none;
  }
  .rating-stars {
    padding: 5px 0;
    .star {
      font-size: 13px;
      line-height: 20px;
    }
  }
  .rating-note {
    display: none;
  }
  .rating-score {
    margin: 0;
    padding: 0 0 10px;
    line-height: 10px;
    &-value {
      font-size: 14px;
      font-weight: bold;
    }
    &-max {
      font-size: 12px;
    }
  }
}
.scroll-bottom, .scroll-top {
  & > .trustpilot-widget,
  #trust-custom-badge {
    bottom: -140px;
  }
}


@media screen and (min-width: 769px) {
  #trust-custom-badge {
    left: 20px;
    bottom: 20px;
  }
  .scroll-bottom, .scroll-top {
    #trust-custom-badge {
      bottom: -140px;
    }
  }
}
@media screen and (min-width: 1025px) {
  #trust-custom-badge {
    width: 91px;
    border-radius: 8px;
    .kaeuferschutz {
      display: block;
      margin: 5px 7px;
      padding: 0 0 5px;
      font-size: 13px;
      line-height: 15px;
      border-bottom: 1px solid rgb(229, 229, 229);
    }
    .rating-stars {
      padding: 0;
    }
    .rating-note {
      display: block;
      margin: 0;
      font-size: 13px;
      line-height: 24px;
    }
    .rating-score {
      padding: 0;
    }
  }
  .scroll-bottom, .scroll-top {
    #trust-custom-badge {
      bottom: -200px;
    }
  }
}
@media screen and (min-width: 1400px) {
  #trust-custom-badge {
    top: 10px;
    left: auto;
    right: 20px;
    bottom: auto;
    z-index: 16;
  }
  .scroll-bottom, .scroll-top {
    #trust-custom-badge {
      bottom: auto;
    }
  }
}

#trust-custom-badge-horizontal {
  .no-shadow();
  position: relative;
  display: block;
  width: 100%;
  box-sizing: content-box;
  margin: 0 0 10px;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid @lightgrey;
  border-bottom: 1px solid @lightgrey;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  &.ci-trustedshops {
    .image-container {
      position: relative;
      display: block;
      height: 25px;
      margin: 0 10px 0 0;
      padding: 0 10px;
      float: left;
      border-right: 1px solid @lightgrey;
      img {
        width: 25px;
        height: 25px;
        float: left;
      }
    }
    .rating-stars {
      float: left;
      margin: 0 10px 0 0;
      padding: 0;
      .star {
        font-size: 13px;
        line-height: 25px;
      }
    }
    .rating-note {
      float: left;
      margin: 0 10px 0 0;
      font-size: 13px;
      line-height: 25px;
    }
    .rating-score {
      float: left;
      margin: 0 10px 0 0;
      line-height: 25px;
      &-value {
        font-size: 14px;
        font-weight: bold;
      }
      &-max {
        font-size: 12px;
      }
    }
  }
  &.ci-trustpilot {
    max-width: 360px;
    text-align: center;
    .trust-rating,
    .trust-logo {
      display: inline-block;
      width: auto;
      height: 18px;
      margin: 0 10px;
    }
  }
}

#trustedshops-custom-box {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 3000001;
  width: 320px;
  padding: 15px 15px 0;
  .trustedshops-custom-box-title {
    position: relative;
    margin: 0 0 12px;
    padding: 0 0 6px;
    border-bottom: 2px solid #ffdc0f;
    .shop-domain {
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
    }
    .close {
      right: 0;
      &:after {
        width: 13px;
        height: 13px;
        background-color: transparent;
        border: none;
        font-family: @icon;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -5px;
        content: '\f054\f053';
        color: #4d4d4d;
        text-indent: -5px;
      }
    }
  }
  .trustedshops-custom-box-button {
    position: relative;
    padding: 10px 20px 10px 10px;
    margin: 0px 0px 10px;
    width: auto;
    float: none;
    display: block;
    background: @white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 2px;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    &:after {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      display: block;
      width: 10px;
      font-family: @icon;
      line-height: 65px;
      text-align: right;
      content: '\f054';
      color: #4d4d4d;
    }
    .button-title {
      display: block;
      margin: 2px 0 8px;
      font-size: 16px;
      line-height: 17px;
      font-weight: bold;
    }
    .button-content {
      display: block;
      font-size: 14px;
      line-height: 17px;
    }
    &.seal {
      .image-container {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        float: left;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    &.assurance {}
    &.rating {
      line-height: 20px;
      .rating-stars {
        display: inline-block;
        margin: 0 20px 0 0;
        .star {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .rating-score {
        display: inline-block;
        &-value {
          font-size: 16px;
          font-weight: bold;
        }
        &-max {
          font-size: 14px;
        }
      }
      .rating-amount {
        display: block;
        margin: 5px 0 0;
        font-size: 12px;
        &-value {
          font-weight: bold;
        }
      }
    }
  }
  .trustedshops-custom-box-footer {
    display: block;
    margin: 0 0 10px;
    font-size: 11px;
    line-height: 20px;
    color: #4d4d4d;
  }
}
@media screen and (min-width: 769px) {
  #trustedshops-custom-box {
    left: 20px;
    bottom: 20px;
  }
}
@media screen and (min-width: 1025px) {
  #trustedshops-custom-box {
    right: 20px;
    left: auto;
    bottom: 110px;
  }
}
@media screen and (min-width: 1400px) {
  #trustedshops-custom-box {
    top: 10px;
    bottom: auto;
  }
}

#trustedshops-custom-widget {
  position: relative;
  .sans-serif-light();
  letter-spacing: 0.05em;
  a:hover {
    text-decoration: none;
  }
  .trustedshops-reviews {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 15px 15px;
    ul {
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        margin: 0 0 15px;
        text-align: center;
      }
    }
    .trustedshops-review {
      display: block;
      margin: 0;
      padding: 30px 15px 15px;
      &:before {
        display: block;
        margin: -30px auto 30px;
        font-family: @icon;
        content: '\e927';
        font-size: 35px;
        line-height: 35px;
      }
      .trustedshops-review-rating {
        display: block;
        margin: -20px 0 30px;
        font-size: 18px;
        line-height: 15px;
      }
      .trustedshops-review-comment {
        display: block;
        height: 30px;
        margin: 0 0 30px;
        font-size: 15px;
        line-height: 15px;
        .sans-serif-bold();
      }
      .trustedshops-review-date {
        display: block;
        font-size: 13px;
        line-height: 15px;
        color: #999999;
      }
    }
  }
  .trustedshops-reviews-footer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    background-color: #ffdc0f;
    -webkit-border-bottom-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    border-bottom-left-radius: 8px;
    &:before {
      position: absolute;
      top: -20px;
      right: 105px;
      display: block;
      border-width: 20px;
      border-style: solid;
      border-color: transparent;
      border-left-color: #ffdc0f;
      content: '';
    }
    .trustedshops-reviews-count {
      padding-top: 10px;
      float: left;
      line-height: 30px;
      b {
        .sans-serif-bold();
      }
    }
    .trustedshops-logo {
      position: relative;
      z-index: 2;
      width: 120px;
      height: auto;
      float: right;
    }
  }
}
@media screen and (min-width: 641px){
  #trustedshops-custom-widget {
    .trustedshops-reviews {
      ul {
        li {
          position: relative;
          width: 50%;
          box-sizing: border-box;
          float: left;
          &:first-child {
            border-right: 1px solid #999999;
          }
        }
      }
    }
  }
  .sidebar {
    #trustedshops-custom-widget {
      .trustedshops-reviews {
        ul {
          li {
            width: 100%;
            float: none;
            &:first-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
}


/* Trustpilot */
body > .trustpilot-widget {
  position: fixed !important;
  left: 10px;
  bottom: 10px;
  z-index: 4;
  transition: bottom 0.25s;
  display: block;
  width: 66px;
  padding: 8px 0 8px 5px;
  text-align: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 8px;
  background-color: @white;
  box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    border-color: #00b67a;
  }
}
@media screen and (min-width: 769px) {
  body > .trustpilot-widget {
    left: 20px;
    bottom: 20px;
  }
  body.scroll-bottom, body.scroll-top {
    & > .trustpilot-widget {
      bottom: -140px;
    }
  }
}
@media screen and (min-width: 1025px) {
  body > .trustpilot-widget {
    width: 91px;
  }
  body.scroll-bottom, body.scroll-top {
    & > .trustpilot-widget {
      bottom: -200px;
    }
  }
}
@media screen and (min-width: 1400px) {
  body > .trustpilot-widget {
    top: 10px;
    left: auto;
    right: 20px;
    bottom: auto;
    z-index: 16;
  }
  body.scroll-bottom, body.scroll-top {
    & > .trustpilot-widget {
      bottom: auto;
    }
  }
}
.trustpilot-widget.horizontal {
}

/* from custom-css-block */
#tl_popupCL1 {
  z-index: 3!important;
}
.fr #l_new {
  display: none;
}

.page-empty {
  .page-wrapper {
    .flex-columns();
    .page-content {
      width: 100%;
      .flex-item-expand();
    }
  }
}


/* pasted to clipboard effect */
/* farbwechsel animation */
@-webkit-keyframes copied {
  from { opacity: 1; }
  to { opacity: 0; }
}
@-moz-keyframes copied {
  from { opacity: 1; }
  to { opacity: 0; }
}
@-ms-keyframes copied {
  from { opacity: 1; }
  to { opacity: 0; }
}
@-o-keyframes copied {
  from { opacity: 1; }
  to { opacity: 0; }
}
@keyframes copied {
  from { opacity: 1; }
  to { opacity: 0; }
}
.copy-voucher-code {
  position: relative;
  cursor: pointer;
  &.copied {
    &:before {
      display: block;
      position: absolute;
      bottom: 100%;
      left: 0;
      opacity: 0;
      -webkit-animation: copied 3s ease-out;
      -moz-animation: copied 3s ease-out;
      -o-animation: copied 3s ease-out;
      animation: copied 3s ease-out;
      font-size: 13px;
      color: @black;
      background-color: @mediumgrey;
      padding: 5px;
      .popup-shadow();
    }
  }
}
.de .copy-voucher-code.copied:before {
  content: 'In die Zwischenablage kopiert';
}
.en .copy-voucher-code.copied:before {
  content: 'Copied to the clipboard';
}
.es .copy-voucher-code.copied:before {
  content: 'Copiado en la memoria portapeles';
}
.fr .copy-voucher-code.copied:before {
  content: 'Copié dans le presse-papiers';
}
.it .copy-voucher-code.copied:before {
  content: 'Copiato negli appunti';
}
.nl .copy-voucher-code.copied:before {
  content: 'Gekopieerd naar het klembord';
}

// shipping costs table
#international-shipping-costs-table {
  border-collapse: collapse;
  font-size: 11px;
  td, th {
    border: 1px solid @lightgrey;
    vertical-align: middle;
    line-height: 15px;
    padding: 5px;
    text-align: center;
  }
  th {
    text-align: left;
  }
  td {
    .sans-serif-regular();
  }
}
@media screen and (min-width: 480px){
  #international-shipping-costs-table {
    font-size: 13px;
  }
  .add-to-overlay {
    #international-shipping-costs-table {
      font-size: 11px;
    }
  }
}
@media screen and (min-width: 768px){
  #international-shipping-costs-table {
    td, th {
      padding: 10px 15px;
    }
  }
}

#liveshow-badge {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  width: 68px;
  height: 120px;
  -webkit-border-radius: 68px;
  -moz-border-radius: 68px;
  border-radius: 68px;
  -webkit-box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .liveshow-badge-link {
    display: block;
    &:after {
      position: absolute;
      left: 38px;
      bottom: 40px;
      font-family: @icon;
      font-size: 30px;
      content: '\f04b';
      color: @white;
      opacity: .85;
    }
  }
  .liveshow-badge-image {
    width: 68px;
    height: 88px;
  }
  .liveshow-badge-text {
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 4px 10% 0;
    line-height: 16px;
    text-align: center;
    color: @darkgrey;
    background-color: @white;
  }
  .close {
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    &:after {
      font-family: @icon;
      font-size: 12px;
      content: '\e901';
      background: transparent;
      text-shadow: 1px 2px 0px rgba(0, 0, 0, 1);
    }
    &:after {
      color: @white;
    }
  }
}
@media screen and (min-width: 769px) {
  #liveshow-badge {
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (min-width: 1280px) {
  #liveshow-badge {
    bottom: 100px;
    width: 93px;
    height: 150px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    &.show-liveshow-badge {
      display: block;
    }
    .liveshow-badge-image {
      width: 93px;
      height: 120px;
    }
    .liveshow-badge-text {
      width: 100%;
      height: 30px;
      padding: 0;
      line-height: 30px;
    }
  }
  .nl,
  .fr,
  .it {
    #liveshow-badge {
      bottom: 220px;
    }
  }
}

.site-overlay.site-overlay-popup-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.8);
  transition: all .5s;
  .site-overlay-content {
    box-sizing: border-box;
    position: relative;
    max-width: 300px;
    margin: 40px auto;
    padding: 40px 0 0;
    z-index: 1001;
    text-align: center;
    background-color: @white;
    .image-link {
      position: relative;
      display: block;
    }
    .voucher-code-label {
      margin: 20px 0 0;
    }
    .voucher-code {
      display: inline-block;
      position: relative;
      margin: 10px 0 0;
      padding: 10px 20px 8px;
      border: 1px solid @mediumgrey;
      background-color: @lightgrey;
      .sans-serif-bold();
      font-size: 16px;
      &:after {
        display: inline-block;
        padding: 0 0 0 5px;
        font-family: @icon;
        content: '\f24d';
        vertical-align: bottom;
        transform: scaleY(-1);
      }
    }
    .btn {
      display: inline-block;
      margin: 20px auto 0;
    }
    .voucher-conditions {
      margin: 20px 10px 0;
      font-size: 10px;
    }
  }
  .site-overlay-close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    &:before {
      font-family: @icon;
      font-size: 20px;
      content: "\e901";
    }
  }
  &.simple {
    a {
      font-size: 0;
      line-height: 0;
    }
  }
  &.uk {
    .site-overlay-content {
      div {
        font-size: 0;
        line-height: 0;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .site-overlay.site-overlay-popup-banner {
    .site-overlay-content {
      max-width: 600px;
    }
    &.uk {
      .site-overlay-content {
        max-width: 300px;
      }
    }
  }
}

.site-overlay-jooli {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.8);
  padding: 20px;
  transition: all .5s;
}
.site-overlay-jooli-content {
  box-sizing: border-box;
  position: relative;
  max-width: 400px;
  margin: 40px auto;
  padding: 50px 40px 40px;
  background-color: #fff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  z-index: 1001;
  text-align: center;
  h2 {
    .sans-serif-bold();
    text-transform: none;
    margin-bottom: 20px;
  }
  .voucher-code {
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-family: monospace;
    font-weight: bold;
    font-size: 16px;
    border: double 3px transparent;
    border-radius: 7px;
    background-image: -moz-linear-gradient(white, white), -moz-linear-gradient(39deg,  #937ef3 0%, #eaccf8 50%, #96e0da 100%);
    background-image: -webkit-linear-gradient(white, white), -webkit-linear-gradient(39deg,  #937ef3 0%,#eaccf8 50%,#96e0da 100%);
    background-image: linear-gradient(white, white), linear-gradient(39deg,  #937ef3 0%,#eaccf8 50%,#96e0da 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  .btn {
    margin-bottom: 20px;
  }
}
.site-overlay-jooli-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.site-overlay-jooli-close:before {
  font-family: @icon;
  font-size: 30px;
  content: "\e901";
}
.site-overlay-jooli.minified {
  top: 100%;
  left: 100%;
  padding: 0;
  overflow: visible;
  transition: all .5s;
}
.site-overlay-jooli.minified .site-overlay-jooli-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 120px;
  padding: 35px 5px;
  margin: 0;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  border: double 3px transparent;
  border-radius: 7px;
  background-image: -moz-linear-gradient(white, white), -moz-linear-gradient(39deg,  #937ef3 0%, #eaccf8 50%, #96e0da 100%);
  background-image: -webkit-linear-gradient(white, white), -webkit-linear-gradient(39deg,  #937ef3 0%,#eaccf8 50%,#96e0da 100%);
  background-image: linear-gradient(white, white), linear-gradient(39deg,  #937ef3 0%,#eaccf8 50%,#96e0da 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.site-overlay-jooli.minified .site-overlay-jooli-content h2 {
  font-size: 13px;
  line-height: 20px;
}
.site-overlay-jooli.minified .site-overlay-jooli-content p {
  display: none;
}
.site-overlay-jooli.minified .site-overlay-jooli-content .voucher-code {
  display: none;
}
.site-overlay-jooli.minified .site-overlay-jooli-content .btn {
  display: none;
}
.site-overlay-jooli.minified .site-overlay-jooli-close {
  display: none;
}
@media screen and (min-width: 1024px){

  .site-overlay-jooli.minified {
    top: 0;
    left: 0;
    right: auto;
  }
  .site-overlay-jooli.minified .site-overlay-jooli-content {
    position: absolute;
    top: 5px;
    bottom: auto;
    left: 10px;
    right: auto;
    width: 170px;
    height: 24px;
    padding: 1px 10px;
    margin: 0;
    h2 {
      margin: 0;
    }
    .voucher-code {
      display: none;
    }
  }
}

#header-banderole {
  .max-width {
    max-width: 1160px;
    min-height: 30px;
    box-sizing: border-box;
    padding: 2px 10px 0;
    color: @white;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.gold {
      background-color: @juwelogold;
    }
    &.blue {
      background-color: #006E96;}
    &.pink {
      background-color: #EB5F9B;
    }
    &.yellow {
      background-color: #AF961E;
    }
    &.black {
      background-color: #111;
    }
    p {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      .sans-serif-bold();
      line-height: 16px;
      text-align: center;
      a {
        color: @white;
        display: block;
        user-select: none;
      }
    }
    .close {
      right: 0;
      width: 30px;
      height: 30px;
      &:after {
        width: 30px;
        height: 30px;
        font-family: @icon;
        font-size: 20px;
        line-height: 30px;
        color: @white;
        content: '\e901';
        background: transparent;
      }
      & + p {
        padding-right: 35px;
      }
    }
  }
}


#alternative-currency {
  .currency-select {
    display: block;
    ul {
      display: inline-block;
      margin: 5px 0 0;
      padding: 10px;
      background-color: @white;
      border-radius: 5px;
      .popup-shadow;
    }
    li {
      display: grid;
      grid-template-columns: 40px auto;
      margin: 0;
      line-height: 30px;
      cursor: pointer;
      border-top: 1px solid @mediumgrey;
      &:first-child {
        border-top: none;
      }
      .code {
        b {
          .sans-serif-bold();
          color: @pink;
        }
      }
      &.selected .label {
        .sans-serif-bold();
      }
    }
  }
}

.alternative-price, .euro-price-bracket {
  display: none !important;
}
.alternative-currency {
  .euro-price-bracket {
    font-style: normal;
    display: inline !important;
  }
  .alternative-price {
    display: inline-block !important;
    &.td {
      display: table-cell;
    }
  }
  .products-grid {
    .price {
      display: none !important;
    }
    .alternative-price {
      display: inline-block !important;
    }
  }
}

// keep in sync with list in shipping.xml
body:not([data-delivery-country=de]) .delivery-country-de {  display: none; }
body:not([data-delivery-country=nl]) .delivery-country-nl {  display: none; }
body:not([data-delivery-country=fr]) .delivery-country-fr {  display: none; }
body:not([data-delivery-country=mc]) .delivery-country-mc {  display: none; }
body:not([data-delivery-country=es]) .delivery-country-es {  display: none; }
body:not([data-delivery-country=at]) .delivery-country-at {  display: none; }
body:not([data-delivery-country=gf]) .delivery-country-gf {  display: none; }
body:not([data-delivery-country=gp]) .delivery-country-gp {  display: none; }
body:not([data-delivery-country=mq]) .delivery-country-mq {  display: none; }
body:not([data-delivery-country=re]) .delivery-country-re {  display: none; }
body:not([data-delivery-country=yt]) .delivery-country-yt {  display: none; }
body:not([data-delivery-country=aw]) .delivery-country-aw {  display: none; }
body:not([data-delivery-country=xa]) .delivery-country-xa {  display: none; }
body:not([data-delivery-country=xb]) .delivery-country-xb {  display: none; }
body:not([data-delivery-country=xc]) .delivery-country-xc {  display: none; }
body:not([data-delivery-country=xd]) .delivery-country-xd {  display: none; }
body:not([data-delivery-country=xe]) .delivery-country-xe {  display: none; }
body:not([data-delivery-country=gb]) .delivery-country-gb {  display: none; }
body:not([data-delivery-country=be]) .delivery-country-be {  display: none; }
body:not([data-delivery-country=bg]) .delivery-country-bg {  display: none; }
body:not([data-delivery-country=ch]) .delivery-country-ch {  display: none; }
body:not([data-delivery-country=dk]) .delivery-country-dk {  display: none; }
body:not([data-delivery-country=ee]) .delivery-country-ee {  display: none; }
body:not([data-delivery-country=fi]) .delivery-country-fi {  display: none; }
body:not([data-delivery-country=gr]) .delivery-country-gr {  display: none; }
body:not([data-delivery-country=hr]) .delivery-country-hr {  display: none; }
body:not([data-delivery-country=ie]) .delivery-country-ie {  display: none; }
body:not([data-delivery-country=it]) .delivery-country-it {  display: none; }
body:not([data-delivery-country=lv]) .delivery-country-lv {  display: none; }
body:not([data-delivery-country=lt]) .delivery-country-lt {  display: none; }
body:not([data-delivery-country=lu]) .delivery-country-lu {  display: none; }
body:not([data-delivery-country=pl]) .delivery-country-pl {  display: none; }
body:not([data-delivery-country=pt]) .delivery-country-pt {  display: none; }
body:not([data-delivery-country=ro]) .delivery-country-ro {  display: none; }
body:not([data-delivery-country=se]) .delivery-country-se {  display: none; }
body:not([data-delivery-country=sk]) .delivery-country-sk {  display: none; }
body:not([data-delivery-country=si]) .delivery-country-si {  display: none; }
body:not([data-delivery-country=cz]) .delivery-country-cz {  display: none; }
body:not([data-delivery-country=hu]) .delivery-country-hu {  display: none; }
body:not([data-delivery-country=cy]) .delivery-country-cy {  display: none; }



.redirected-from-rocks-banner {
  position: relative;
  padding: 10px;
  background: @juwelogold;
  background: linear-gradient(to right bottom,  @pink 0%,@purple 100%);
  color: @white;
  text-shadow: 0px 0px 10px @pink;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../images/favicon.png');
    background-size: 17px;
    opacity: .04;
    content: '';
  }
  .close {
    top: 10px;
    right: 10px;
    z-index: 2;
    &:after {
      background-color: transparent;
      font-family: @icon;
      font-size: 20px;
      content: '\e901';
    }
  }
  h2 {
    margin: 0 40px;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }
  h3 {
    margin: 10px 40px 0;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
}
.no-touchevents {
  .redirected-from-rocks-banner {
    border-bottom: 1px solid @darkgrey;
  }
}
@media screen and (min-width: 769px) {
  .redirected-from-rocks-banner {
    padding: 10px 20px;
    .close {
      top: 10px;
      right: 20px;
    }
    h2 {
      font-size: 22px;
      line-height: 22px;
    }
    h3 {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .redirected-from-rocks-banner {
    padding: 20px calc((100% - 1160px)/2);
    .close {
      top: 20px;
      right: calc((100% - 1160px)/2);
    }
    h2 {
      font-size: 25px;
      line-height: 25px;
    }
    h3 {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.whatsapp-floater {
  position: fixed;
  left: auto;
  right: calc(0% + 24px);
  bottom: calc(0% + 20px);
  z-index: 9999;
  a {
    display: block;
    width: 64px;
    height: 64px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 20px 0px;
    border-radius: 50%;
    background-color: #25d366;
    color: @white;
    text-decoration: none;
    &:before {
      display: block;
      .icon();
      font-size: 40px;
      line-height: 64px;
      text-align: center;
      content: '\e938';
    }
  }
}